<template>
  <ValidationProvider
    ref="provider"
    :name="name"
    class="form-field"
    v-slot="{ errors, invalid, reset }"
  >
    <slot name="header" />
    <FormFieldErrors v-if="errorsPosition === 'before'" :errors="errors" />
    <slot :invalid="invalid" :reset="reset" />
    <FormFieldErrors v-if="errorsPosition === 'after'" :errors="errors" />
  </ValidationProvider>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';

  import FormFieldErrors from 'src/components/form/FormFieldErrors/FormFieldErrors.vue';

  export default {
    name: 'FormField',
    components: {
      FormFieldErrors,
      ValidationProvider
    },
    props: {
      name: String,
      errorsPosition: {
        type: String,
        default: 'after',
        validator: v => ['before', 'after'].includes(v),
      },
    },
    mounted() {
      this.$watch(() => {
        return this.$refs.provider.errors;
      }, (val) => {
        if (val && val.length !== 0) {
          this.$emit('invalid');
        } else {
          this.$emit('valid');
        }
      });
    },
  }
</script>