<template>
  <div>
    <WithSpinnerPlaceholder :loading="isLoading">
      <BasicButton
        v-if="isGuest"
        appearance="primary"
        :size="mediaQueries.mobile ? 'l' : 'm'"
        href="/users/auth/tmid"
        @click.native="sendAnalytics()"
      >
        <span>Отправить заявку</span>
      </BasicButton>

      <BasicButton
        v-else
        appearance="primary"
        :size="mediaQueries.mobile ? 'l' : 'm'"
        @click="checkPossibilityAndOpenModal"
        :disabled="isLoading"
      >
        <span>Отправить заявку</span>
      </BasicButton>
    </WithSpinnerPlaceholder>

    <ConsultationRequestFormModal
      v-if="showRequestModal"
      @close="onClose"
      @success="openSuccessModal"
      :expert-login="expertLogin"
    />

    <DecoratedResultModal
      v-else-if="showUnfinishedModal"
      @close="onClose"
      appearance="warning"
      title="Запрос на консультацию"
    >
      <template #default>
        <p>
          У вас имеется незавершенная консультация. Чтобы отправить новый
          запрос, необходимо завершить текущую консультацию.
          <br />
          <a :href="`/conversations/${previousExpertLogin}`">
            Свяжитесь с экспертом</a
          >.
        </p>
      </template>

      <template #actions>
        <BasicButton appearance="secondary" size="l" @click="onClose">
          Закрыть
        </BasicButton>
      </template>
    </DecoratedResultModal>
    <DecoratedResultModal
      v-else-if="showLockedModal"
      @close="onClose"
      appearance="error"
      title="Запрос на консультацию"
    >
      <template #default>
        <p>
          Сожалеем, но отправка запроса этому эксперту недоступна, так как он
          вас заблокировал. Вы можете отправить запрос
          <a @click="preventNavigationForExpertsList" href="/experts">
            другому эксперту</a
          >.
        </p>
      </template>

      <template #actions>
        <BasicButton appearance="secondary" size="l" @click="onClose">
          Закрыть
        </BasicButton>
      </template>
    </DecoratedResultModal>
    <DecoratedResultModal
      v-else-if="showLockedByMeModal"
      @close="onClose"
      appearance="error"
      title="Запрос на консультацию"
    >
      <template #default>
        <p>
          Сожалеем, но отправка запроса этому эксперту недоступна, так как вы
          его заблокировали. Вы можете отправить запрос
          <a @click="preventNavigationForExpertsList" href="/experts">
            другому эксперту</a
          >.
        </p>
      </template>

      <template #actions>
        <BasicButton appearance="secondary" size="l" @click="onClose">
          Закрыть
        </BasicButton>
      </template>
    </DecoratedResultModal>

    <DecoratedResultModal
      v-else-if="showSuccessModal"
      @close="onClose"
      appearance="success"
      title="Запрос на консультацию"
    >
      <template #default>
        <p>
          Запрос отправлен. Ответ отобразится в
          <a :href="`/conversations/${expertLogin}`">диалоге с экспертом</a>.
        </p>
      </template>
      <template #actions>
        <BasicButton appearance="secondary" size="l" @click="onClose">
          Закрыть
        </BasicButton>
      </template>
    </DecoratedResultModal>
  </div>
</template>
<script>
import { notify } from 'src/notify.js';
import { ExpertsService } from 'src/services/experts.js';
import { captureException } from 'src/utils/sentry.js';

import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import ConsultationRequestFormModal from 'src/components/ConsultationRequestFormModal/ConsultationRequestFormModal.vue';
import DecoratedResultModal from 'src/components/DecoratedResultModal/DecoratedResultModal.vue';
import WithSpinnerPlaceholder from 'src/components/placeholders/WithSpinnerPlaceholder/WithSpinnerPlaceholder.vue';

const states = {
  formOpened: 'formOpened',
  unfinished: 'unfinished',
  locked: 'locked',
  locked_by_me: 'locked_by_me',
  success: 'success',
};

export default {
  name: 'ConsultationRequestButton',
  components: {
    ConsultationRequestFormModal,
    WithSpinnerPlaceholder,
    DecoratedResultModal,
    BasicButton,
  },
  inject: ['mediaQueries', 'visitor'],
  props: {
    expertLogin: {
      type: String,
      required: true,
    },
    place: {
      type: String,
      required: true,
      validator: (v) => ['card', 'profile'].includes(v),
    },
  },
  data() {
    return {
      state: null,
      previousExpertLogin: null,
      isLoading: false,
    };
  },
  methods: {
    async checkPossibilityAndOpenModal() {
      this.sendAnalytics();
      this.isLoading = true;
      try {
        const {
          status,
          previousExpertLogin = null,
        } = await ExpertsService.getAvailableStatus(this.expertLogin);

        this.previousExpertLogin = previousExpertLogin;

        let newState;

        if (status === 'ok') newState = states.formOpened;
        else newState = states[status];

        if (!newState) throw new Error('Что-то пошло не так');

        this.state = newState;
      } catch (error) {
        notify({ message: error.message, type: 'error' });
        captureException(error);
      } finally {
        this.isLoading = false;
      }
    },

    openSuccessModal() {
      this.state = states.success;
    },
    onClose() {
      this.state = null;
    },
    preventNavigationForExpertsList(event) {
      if (window.location.pathname === '/experts') {
        event.preventDefault();
        this.state = null;
      }
    },
    sendAnalytics() {
      window.helpers.trackEvent('experts', 'contact', `ex_${this.place}`);
    },
  },
  computed: {
    isGuest() {
      return this.visitor.isGuest;
    },
    showRequestModal() {
      return this.state === states.formOpened;
    },
    showLockedByMeModal() {
      return this.state === states.locked_by_me;
    },
    showLockedModal() {
      return this.state === states.locked;
    },
    showUnfinishedModal() {
      return this.state === states.unfinished;
    },
    showSuccessModal() {
      return this.state === states.success;
    },
    links() {
      return {
        packages: `/companies/${this.companyId}/cp/billing/packages/new`,
        resumeAccess: `/companies/${this.companyId}/cp/billing/resume_accesses/new`,
      };
    },
  },
};
</script>
