<template>
  <ValidationObserver
    ref="observer"
    slim
    v-slot="{ errors }"
  >
    <form
      ref="form"
      class="basic-form"
      @submit.prevent="onSubmit"
    >
      <slot :requestInProcess="Boolean(request)" :errors="errors" />
    </form>
  </ValidationObserver>
</template>

<script>
  import { ValidationObserver } from 'vee-validate';

  import { notify } from 'src/notify.js';
  import { isCancelledRequest } from 'src/services/api.js';
  import { getParentBySelector } from 'src/utils/dom.js';
  import { captureException } from 'src/utils/sentry.js';

  /**
   * Форма ограниченно поддерживает клиентскую валидацию.
   * Она самописная, не декларативная, но нам её пока надо мало и оно работает.
   * Чтобы оно таки работало в вашей форме, нужно подписаться на событие `client-validation`
   * и в обработчике сделать все проверки и установить ошибки через методы
   * `setError` или `setErrors`.
   *
   * Чтобы ошибки сбрасывались при изменении данных формы,
   * нужно передать в проп `params` объект со значениями, к которому привязаны инпуты.
   *
   * Если он имеет несколько степеней вложенности, например:
   * ```
   * { data: { form: { inputOne: string } } }
   * ```
   * то можно использовать проп `fieldDictGetter` чтобы указать,
   * где в `params` лежат сами значения:
   * ```
   * :field-dict-getter="params => params.data.form"
   * ```
   *
   * Если в форме не нужна валидация на клиенте, то не нужно подписываться на
   * событие `client-validation` - если в `params` не попадают значения инпутов,
   * то это сделает повторную отправку форму после исправления ошибок валидации
   * невозможной.
   */
  export default {
    name: 'BasicForm',
    components: {
      ValidationObserver,
    },
    props: {
      action: {
        type: Function,
        required: true,
      },
      params: Object,
      appendParams: Boolean,
      // скролл к первому .form-field с ошибкой или .form-field-errors__error
      scrollToError: Boolean,
      fieldDictGetter: {
        type: Function,
        default: (params) => params,
      }
    },
    emits: ['client-validation', 'success', 'validationError', 'error'],
    data() {
      return {
        request: null,
        validationErrors: null,
      }
    },
    watch: {
      form: {
        deep: true,
        handler(value, prevValue) {
          if (!this.validationErrors) return;

          const changedKeys = Object.keys(this.validationErrors).filter(key => {
            return prevValue[key] !== value[key]
          });
          this.removeErrors(changedKeys);
        }
      }
    },
    methods: {
      async onSubmit() {
        if (this.request !== null) return;

        this.$emit('client-validation');
        await this.$nextTick();

        if (this.hasClientErrors) {
          this.maybeScrollToError();

          return;
        }

        const teardown = () => { this.request = null; };

        const formData = new FormData(this.$refs.form);

        let params = { data: formData };

        if (this.params) {
          if (this.appendParams) {
            Object.keys(this.params).forEach(key => {
              formData.append(key, this.params[key]);
            });
          } else {
            params = { ...params, ...this.params };
          }
        }
        
        this.request = this.action(params);
        this.request
          .then(({ data }) => {
            const { redirectTo } = data;
            if (redirectTo) {
              window.location.replace(redirectTo);
            } else {
              this.$emit('success', data);
            }
          })
          .catch((requestError) => {
            if (isCancelledRequest(requestError)) return;

            const { message } = requestError;
            try {
              if (requestError.response) {
                this.handleResponseErrors(requestError);
              } else {
                notify({ message, type: 'error' });
              }
            } catch(error) {
              console.error(error);
              captureException(error);
            }
          })
          .then(teardown, teardown);
      },
      handleResponseErrors(requestError) {
        let { message } = requestError;
        const { data } = requestError.response;

        if (data.errors) {
          this.setErrors(data.errors);

          this.$emit('validationError', requestError);

          this.maybeScrollToError();
        } else {
          if (data.error && data.error.message === 'Bad email') {
            window.location.href = '/'
            return;
          }
          message = data.message || message;
          this.$emit('error', requestError);
          notify({ message, type: 'error' });
        }
      },
      async maybeScrollToError() {
        if (this.scrollToError) {
          await this.$nextTick();
          this.scrollToFirstError();
        }
      },
      scrollToFirstError() {
        const firstError = this.$refs.form.querySelector('.form-field-errors__error');
        const target = getParentBySelector(firstError, '.form-field') || firstError;

        if (!target) return;

        target.scrollIntoView({
          block: 'start',
          inline: 'nearest',
          behavior: 'smooth',
        });
      },
      setErrors(errors) {
        this.$refs.observer.setErrors(errors);
        this.validationErrors = errors;
      },
      setError(key, messages) {
        const newErrors = { ...this.validationErrors, [key]: messages };
        this.setErrors(newErrors);
      },
      removeError(key) {
        const newErrors = { ...this.validationErrors, [key]: false };
        this.setErrors(newErrors);
      },
      removeErrors(keys) {
        const newErrors = { ...this.validationErrors };

        keys.forEach(key => {
          newErrors[key] = false;
        });

        this.setErrors(newErrors);
      },
    },
    computed: {
      hasClientErrors() {
        return this.hasValidationErrors && this.$listeners['client-validation'];
      },
      hasValidationErrors() {
        return !!(this.validationErrors && Object.values(this.validationErrors).some(Boolean));
      },
      form() {
        return this.fieldDictGetter(this.params);
      }
    },
  }
</script>
