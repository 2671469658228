import queryString from 'query-string';

import { apiUrlV1, cancellableRequest } from 'src/services/api.js';
import { createObjectMapper } from 'src/utils/object.js';

const paramsMap = {
  query: 'q',
  requestGroup: 'rid',
  specializations: 'sid',
  skills: 'skills',
  qualification: 'qid',
  rateTo: 'rate',
  rateFrom: 'rateFrom',
  currencies: 'currency',
  freeOnly: 'freeOnly',
  freeIntro: 'freeIntro',
  sort: 'sort',
  dir: 'dir',
};

const mapLocationParams = createObjectMapper({
  filterEmpty: true,
  defaults: {
    sortBy: 'lastActive',
    dir: 'desc',
    currency: 'rur',
  },
  map: paramsMap,
});

const mapRequestParams = createObjectMapper({
  filterEmpty: true,
  defaults: {
    currency: 'rur',
  },
  map: paramsMap,
});

export const ExpertsService = {
  getList(params) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrlV1}/experts`,
      params: mapRequestParams(params),
    });
  },
  setExpertScore({ alias, score }) {
    return cancellableRequest({
      method: 'put',
      url: `${apiUrlV1}/experts/${alias}/score`,
      params: { score },
    });
  },
  cancelByUser({ alias }) {
    return cancellableRequest({
      method: 'put',
      url: `${apiUrlV1}/experts/${alias}/cancel`,
    });
  },
  /**
   * @param {{
   *   form: {
   *     score?: number,
   *        liked?: string,
   *        disliked?: string,
   *        improve?: string,
   *   }
   * }} params
   */
  sendFeedback(params) {
    return cancellableRequest({
      method: 'post',
      url: `${apiUrlV1}/experts/feedback`,
      data: params,
    });
  },
  getMappedParams(params) {
    return mapLocationParams(params);
  },
  syncLocation(params) {
    const mappedParams = mapLocationParams(params);
    const urlQuery = queryString.stringify(mappedParams, {
      arrayFormat: 'bracket',
    });
    // TODO:frontend повесить событие в ga
    window.history.replaceState(
      params,
      window.location.title,
      `${window.location.pathname}?${urlQuery}`,
    );
  },
  getServices() {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrlV1}/profile/expert/services`,
    });
  },
  getActiveServices() {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrlV1}/profile/expert/active_services`,
    });
  },
  payService(params) {
    return cancellableRequest({
      method: 'post',
      url: `${apiUrlV1}/profile/expert/pay`,
      params,
    });
  },
  closeBillingBanner() {
    return cancellableRequest({
      method: 'patch',
      url: `${apiUrlV1}/profile/expert/close_banner`,
    });
  },
  getAvailableStatus(expertLogin) {
    const params = queryString.stringify({
      expertLogin,
    });
    return cancellableRequest({
      method: 'get',
      url: `${apiUrlV1}/experts/consultations/available?${params}`,
    }).then(({ data }) => data);
  },
  requestConsultation({ expertLogin, message }) {
    return cancellableRequest({
      method: 'post',
      url: `${apiUrlV1}/experts/consultations`,
      data: {
        form: { expert: expertLogin, request: message },
      },
    });
  },
};
