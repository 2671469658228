<template>
  <div
    v-if="errors && errors.length !== 0"
    class="form-field__errors"
  >
    <div class="form-field-errors">
      <div
        v-for="error in errors"
        :key="error"
        class="form-field-errors__error"
        v-text="error"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FormFieldErrors',
    props: {
      errors: Array
    }
  };
</script>